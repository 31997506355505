// !VA _custom_mq.scss
.site-wrapper {
  @include mphone {
    width: 375px;
  }
  @include lphone {
    width: 414px;
    width: 100vw;
  }
  @include tablet {
    width: 100%;
  }
  @include desktop {
    width: 100%;
  }
  @include ldesktop {
    width: 100%;
  }
}

.page {
  // !VA Required to set Read More position to absolute
  @include tablet {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
  @include desktop {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
  @include ldesktop {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
  position: relative;
}

// !VA Page height and top padding
.page-home-1 {
  @include tablet {
    height: calc(100vh - 55px);
    padding-top: 40px;
    padding-bottom: 100px;
  
  }
  @include desktop {
    padding-top: 40px;
  }
  @include ldesktop {
    // height: calc(100vh - 55px);
    padding-top: 60px;
  }
}

.home-crossroads {
  @include mphone {
    h3 {
      width: 100%;
      font-size: 1.5rem;
      line-height: 2.25rem;
      margin-bottom: 1rem;
    }
    .figure-mobile {
      display: block;
      width: 200px !important;
      margin: 0 auto 1.5rem;
    }
    p {
      margin: 0 0 1rem;
      padding: 0;
    }
  }
  @include lphone {
    h3 {
      font-size: 1.625rem;
      line-height: 2.25rem;
    }
    .figure-mobile {
      display: block;
      width: 240px;
      margin: 1rem  auto;
    }
  }
  @include tablet {
    .back-to-top {
      display: flex;
    }
    .subtitle {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 2rem;
    }
    h3 {
      display: inline-block;
      width: auto;
      &.rule-above:before {top: 0; }
      &.rule-below:after { bottom: 0; } 
    }
    .figure-mobile {
      display: none;
    }
    .figure-desktop {
      display: block;
      width: 240px !important;
      margin: 0 2rem 2rem;
      float: right;
    }
  }
  @include desktop {
    padding-top: 1rem;
    .back-to-top {
      display: flex;
      padding: 2rem 0;
    }
    .subtitle {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 2rem;
    }
    h3 {
      display: inline-block;
      width: auto;
      &.rule-above:before {top: 0; }
      &.rule-below:after { bottom: 0; } 
    }
    .figure-mobile {
      display: none;
    }
    .figure-desktop {
      display: block;
      width: 280px !important;
      margin: 0 2rem 2rem;
      float: right;
    }
  }
  @include ldesktop {
    padding-top: 3rem;
    .back-to-top {
      display: flex;
      padding: 2rem 0;
    }
    h3 {
      font-size: 2.25rem;
      line-height: 3rem;
    }
    .figure-mobile {
      display: none;
    }
    .figure-desktop {
      display: block;
      width: 300px !important;
      margin: 0 2rem 2rem;
      float: right;
    }
  }
}





.container {
  @include mphone {
    width: 335px;
  }
  @include lphone {
    width: 375px;
  }
  @include tablet {
    align-items: flex-start;
    width: 748px;
  }
  @include desktop {
    align-items: flex-start;
    width: 940px;
  }
  @include ldesktop {
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
  }
}


.read-more {
  @include tablet {
    display: none;
    // position: absolute;
    // top: 740px;
    // align-items: center;
    // justify-content: center;
    // width: 100%;
    // height: 50px;
    // z-index: 3;
    // button {
    //   width: 200px;
    //   height: 100%;
    //   font-size: 1.063rem;
    // }
  }
  @include desktop {
    display: flex;
    position: absolute;
    top: 860px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    z-index: 3;
    button {
      width: 200px;
      height: 100%;
      font-size: 1.063rem;
    }
  }
  @include ldesktop {
    position: absolute;
    top: 780px;
    width: 100%;
    height: 50px;
    button {
      width: 200px;
      height: 100%;
      font-size: 1.063rem;
    }
  }
}

.header-content-items {
  @include tablet {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 768px;
  }
  @include desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 960px;
  }
  @include ldesktop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // !VA This is the width for the full menu
    // width: 90%
    // !VA This is the width for just the home icon
    width: 1300px;
  }
}

// !VA Chage to to page-wrap
.page-wrap {
  @include tablet {
    // !VA TEMP SPACING
    align-items: flex-start;
    justify-content: flex-start;
  }
  @include desktop {
    align-items: flex-start;
    justify-content: flex-start;
  }
  @include ldesktop {
    align-items: flex-start;
    justify-content: flex-start;
  }
}

article {
  @include mphone {
    width: 335px;
  }
  @include lphone {
    width: 374px;
  }
  @include tablet {
    width: 100%;
  }
  @include desktop {
    width: 100%;
  }
  @include ldesktop {
    width: 100%;
  }
}

// !VA Styling for home page grid
.home-landing {
  @include mphone {
    // !VA Title
    .grid-item-1 {
      height: 16vh;
      h1 {
        padding: .25rem 0 .75rem;
        font-size: 2.75rem;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: 2.5rem;
        text-align: center;
        vertical-align: baseline;
        &.rule-above:before {top: 0; }
        &.rule-below:after { bottom: 0; } 
      }
      .urlbar {
        top: 41vh;
        .content__url-text {
          font-size: 1rem;
          letter-spacing: .18rem;
        }
      }
    }
    .grid-item-2 {
      width: 100%;
      height: 32vh !important;
      .hero-img {
        width: 100%;
        height: 100%;
        background-position: center 33%;
      }
    }
    .grid-item-3 {    
      height: 16vh;
      .subtitle-text {
      // !VA Extend the dot separator
      width: 338px;
    }
      .separator {
        width: 100%;
        margin-top: 1vh;
        padding-bottom: 1vh;
        // padding-top: 1vh;
        .dot-separator {
          width: 100%;
        }
      }
    }
    // !VA Section
    .grid-item-4 {
      display: none;
    }
    // !VA Bandpic
    .grid-item-5 {

      height: 24vh;
      // overflow: hidden;
      .bandpic-img {
        width: 100%;
        background-repeat: no-repeat;
        background-position: -5px center;
        background-position: -48px -8px;
        background-size: 130%;
      }
    }
  }
  @include lphone {
    .grid-item-1 {
      height: 16vh;
      h1 {
        padding: .5rem 0 1rem;
        font-size: 3.25rem;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: 2.5rem;
        text-align: center;
        vertical-align: baseline;
        &.rule-above:before {top: 0; }
        &.rule-below:after { bottom: 0; } 
      }
      .urlbar {
        top: 43vh;
        .content__url-text {
          font-size: 1.125rem;
          letter-spacing: .1rem;
        }
      }
    }
    .grid-item-2 {
      width: 374px;
      height: 32vh !important;
      .hero-img {
        width: 100%;
        height: 100%;
        background-position: center 22%;
      }
    }
    .grid-item-3 {    
      height: 18vh;
      h2 {
        // font-size: 1.75rem;/
        font-weight: 700;
        letter-spacing: .04rem;
      }
      .separator {
        width: 100%;
        margin-top: 1vh;
        padding-bottom: 1vh;
        // padding-top: 1vh;
        .dot-separator {
          width: 100%;
        }
      }
    }
    // !VA Section
    .grid-item-4 {
      display: none;
    }
    // !VA Bandpic
    .grid-item-5 {
      height: 24vh !important;
      .bandpic-img {
        width: 100%;
        height: 100%;
        background-position: -40px -10px;
        background-size: 120%;
      }
    }
  }
  @include tablet {
    .grid-item-1 {
      height: auto;
      margin: 0;
      padding: 0;
      h1 {
        padding: .5rem 0 1.5rem;
        font-size: 4.25rem;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: 2.5rem;
        text-align: center;
        vertical-align: baseline;
        // !VA 
        &.rule-above:before {border: 0; }
        &.rule-below:after { border: 0; } 
      }
      .urlbar {
        position: relative;
        top: 0;
        margin-bottom: 2rem;
        background: 0;
        .content__url-text {
          margin: 0;
          font-size: 1.625rem;
          letter-spacing: .18rem;
          &.rule-above {
            padding-top: .25rem;
            &:before {
              border-top: 1px solid var(--horiz-rule-color)
            }
          }
          &.rule-below {
            padding-bottom: .625rem;
            &:after {
              border-bottom: 1px solid var(--horiz-rule-color)
            }
          }
        }
      }
    }
    .grid-item-2 {
      width: 520px;
      height: 280px;
      margin: 0 auto .5rem;
      .hero-img {
        width: 100%;
        height: 100%;
        background-position: center 28%;
      }
    }
    .grid-item-3 {    
      display: block;
      width: 525px;
      height: 94px;
      margin: 0 auto;
      padding: 0;
      h2 {
        letter-spacing: .04rem;
      }
      .separator {
        width: 100%;
        margin-top: 0;
        padding-bottom: 0;
        // padding-top: 1vh;
        .dot-separator {
          width: 100%;
        }
      }
    }
    // !VA Section
    .grid-item-4 {
      display: block;
      width: 540px;
      margin: 0 auto;
      margin-bottom: .5rem;
      p {
        font-size: 1.313rem;
        line-height: 2rem;
      }
    }
    // !VA Bandpic
    .grid-item-5 {
      height: 20vh !important;
      .bandpic-img {
        width: 540px;
        height: 100%;
        background-position: -10px -px;
        background-size: 103%;
      }
    }
  }
  @include desktop {
    position: relative;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 200px;
    padding-top: 2rem;
    .grid-item-1 {
      width: 440px;
      height: 120px;
      padding-right: 20px;
      grid-column-start: 1;
      h1 {
        padding: 1rem 0 1.5rem;
        font-size: 4.125rem;
        font-weight: 700;
        letter-spacing: .06rem;
        line-height: 2.5rem;
        text-align: center;
        vertical-align: baseline;
        &.rule-above:before {border: 0; }
        &.rule-below:after { border: 0; } 
      }
      .urlbar {
        position: relative;
        top: 0;
        margin-bottom: 2rem;
        background: 0;
      }
    }
    .grid-item-2 {
      grid-column-start: 2;
      grid-row-start: 1;
      grid-row-end: 2;
      height: 360px;
    }
    .grid-item-3 {
      position: relative;
      top: -80px;
      align-items: flex-start;
      justify-content: flex-start;
      width: 440px;
      margin: 1.5rem 0 0;
      padding-right: 20px;
      grid-column-start: 1;
      grid-row-start: 2;
      h2 {
        font-size: 2rem;
        font-weight: 700;
        letter-spacing: .1rem;
        text-align: center;
      }
      .separator {
        width: 100%;
        margin: .25rem 0;
        .dot-separator {
          width: 100%;
        }
      }
    }
    .grid-item-4 {
      display: block;
      width: 440px;
      padding-right: 20px;
      grid-column-start: 1;
      p {
        padding: 0 0 .75rem;
        font-size: 1.375rem;
        line-height: 2.125rem;
      }
    }
    .grid-item-5 {
      position: absolute;
      top: -70px;
      width: 100%;
      height: 220px;
      margin-top: 80px;
      grid-column-start: 2;
      grid-row-start: 3;
      .bandpic-img {
        width: 100%;
        height: 100%;
        background-position: -20px center;
        background-size: 106%;
      }
    }
  }
  @include ldesktop {
    display: grid;
    width: 100%;
    grid-template-columns: 55% 45%;
    grid-template-rows: 110px;
    .grid-item-1 {
      display: block;
      width: 560px;
      height: 130px;
      padding-top: 0;
      grid-column-start: 1;
      h1 {
        width: 100%;
        padding: 1rem 0 2.25rem;
        font-size: 5.25rem;
        font-weight: 700;
        letter-spacing: .09rem;
        line-height: 2.5rem;
        text-align: center;
        vertical-align: baseline;
      }
      .urlbar {
        position: relative;
        top: 0;
        margin-bottom: 2rem;
        background: 0;
      }
    }
    .grid-item-2 {
      width: 100%;
      height: 360px;
      grid-column-start: 2;
      grid-row-start: 1;
      grid-row-end: 2;
      .hero-img {
        background-position: center -100px;
        background-size: 100%;
      }
    }
    // !VA .subtitle
    .grid-item-3 {
      position: relative;
      top: 0;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      height: 220px;
      padding-right: 20px;
      grid-column-start: 1;
      grid-row-start: 2;
      h2 {
        font-size: 2.563rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
      }
      .separator {
        width: 100%;
        margin: .25rem 0;
        .dot-separator {
          width: 100%;
        }
      }
    }
    .grid-item-4 {
      display: block;
      position: relative;
      top: 36px;
      width: 100%;
      padding-right: 20px;
      grid-column-start: 1;
      p {
        padding: 0 120px .75rem 0;
        font-size: 1.563rem;
        line-height: 2.125rem;
      }
    }
    .grid-item-5 {
      position: relative;
      top: -40px;
      width: 100%;
      height: 240px;
      grid-column-start: 2;
      grid-row-start: 3;
      .bandpic-img {
        position: relative;
        width: 100%;
        height: 100%;
        background-position: -15px -10px;
        background-size: 106%;
      }
    }
  }
}

.hide {
  @include tablet {
    display: block;
  } 
  @include desktop {
    display: block;
  }
  @include ldesktop {
    display: block;
  }
}





.urlbar {
  @include tablet {
    display: flex;
    position: relative;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    width: 100%;
    p {
      display: block;
      margin-bottom: 2rem; 
      padding: .125rem 0 .365rem;
      font-size: 1.365rem;
      letter-spacing: .2rem;
      line-height: 1.5rem;
      text-align: left;
    }
  }
  @include desktop {
    display: flex;
    position: relative;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    width: 100%;
    p {
      display: block;
      padding: .25rem 0 .5rem;
      font-size: 1.5rem;
      letter-spacing: .25rem;
      line-height: 1.5rem;
      text-align: left;
      margin: 0;
      font-size: 1.5rem;
      letter-spacing: .17rem;
      &.rule-above {
        padding-top: .25rem;
        &:before {
          border-top: 1px solid var(--horiz-rule-color)
        }
      }
      &.rule-below {
        padding-bottom: .625rem;
        &:after {
          border-bottom: 1px solid var(--horiz-rule-color)
        }
      }
    }
  }
  @include ldesktop {
    display: flex;
    position: relative;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    p {
      letter-spacing: .3rem;
      line-height: 1.5rem;
      text-align: left;
      display: block;
      margin: 0 auto;
      font-size: 1.875rem;
      letter-spacing: .28rem;
      text-align: center;
      &.rule-above {
        padding-top: .5rem;
        &:before {
          border-top: 1px solid var(--horiz-rule-color)
        }
      }
      &.rule-below {
        padding-bottom: .75rem;
        &:after {
          border-bottom: 1px solid var(--horiz-rule-color)
        }
      }
    }
  }
}



.urlbar-mobile {
  @include mphone {
    top: auto;
    bottom: 6%;
    width: 100%;
    height: 26px;
  }
  @include lphone {
    top: auto;
    bottom: 6%;
    width: 100%;
    height: 26px;
  }
  @include tablet {
    display: none;
  }
  @include desktop {
    display: none;
  }
  @include ldesktop {
    display: none;
  }
}

.urlbar--url-text {
  @include mphone {
    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  @include lphone {
    p {
      font-size: 1.188rem;
      line-height: 1.75rem;
    }
  }
}


section {
  @include lphone {
    padding-bottom: 1rem;
  }
  @include tablet {
    display: flex;
  }
  @include desktop {
    display: flex;
  }
  @include ldesktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    figure {
      width: 360px;
      margin: 0 2rem 2rem;
      float: right;
      img {
        width: 100%;
      }
      figcaption {
        padding-left: .75rem;
        font-size: 17px;
        line-height: 23px;
        a {
          color: var(--link-color);
        }
      }
    }
  }
}


.header-content-items-mobile {
  @include mphone {
    margin: 1rem auto 2rem;
  }
  @include lphone {
    margin: 1rem auto 2rem;
  }
}

// .rule-above, .rule-below {
//   position: relative;
//   text-align: center;

//   &:before, &:after {
//     position: absolute;
//     display: block;
//     margin: 0 auto;
//     content: '';
//     width: 100%;
//   }
// }

// .rule-above {
//   &:before {
//     top: 2px;
//     border-top: 1px solid var(--horiz-rule-color);
//   }
// }

// .rule-below {
//   &:after {
//     bottom: 2px;
//     border-bottom: 1px solid var(--horiz-rule-color);
//   }
// }

.about {
  @include mphone {
    .subtitle {
      margin: 1rem auto;
      padding: 0;
      h3 {
        // font-size: 1.75rem;
        letter-spacing: .13rem;
      }
    }
    .album-img {
      width: 240px;
      height: auto;
      margin: 1rem auto;
    }
    .slideshow-container {
      width: 260px;
      height: 260px;
      margin: 1rem auto;
      overflow: hidden;
    }
  
    .slideshow-slide {
      width: 100%;
      height: 100%;
      // margin: 1rem auto;
  
      img {
        width: 260px;
        height: 260px;
      }
    }
  }
  @include lphone {
    .subtitle {
      margin: 1rem auto;
      padding: 0 .5rem;
      h3 {
        padding: .25rem 0 .365rem;
        font-size: 1.813rem;
        letter-spacing: .14rem;
      }
    }
    p {
      padding: 0 .5rem .75rem;
      line-height: 2rem;
    }
    .album-img {
      width: 240px;
      height: auto;
      margin: 1rem auto;
    }
    .slideshow-container {
      width: 300px;
      height: 300px;
      margin: 1rem auto;
      overflow: hidden;
    }
  
    .slideshow-slide {
      width: 100%;
      height: 100%;
      // margin: 1rem auto;
  
      img {
        width: 300px;
        height: 300px;
      }
    }
  }
  @include tablet {
    section {
      display: block;
      width: 100%;
      padding-top: 3rem;
    }
    .section-3 {
      .album-img {
        position: relative;
        top: 0;
        width: 340px;
        height: 300px;
        margin: 0 20px 0 40px;
        padding-left: 40px;
        float: right;
      } 
    }
    .subtitle {
      width: 340px;
      // margin: 1rem auto;
      margin: 0 0 1rem;
      padding: 0 .5rem;
    }
    .album-img {
      position: relative;
      top: -60px;
      width: 340px;
      height: 240px;
      margin: 0 20px 0 40px;
      padding-left: 40px;
      float: right;
    }
    // .slideshow-container {
    //   position: relative;
    //   top: -60px;
    //   width: 340px;
    //   height: 240px;
    //   margin: 0 20px 0 40px;
    //   padding-left: 40px;
    //   margin: 1rem auto;
    //   overflow: hidden;
    //   float: right;
    //   img {
    //     width: 340px;
    //     height: 340px;
    //   }
    // }
    .slideshow-container {
      position: relative;
      top: -26px;
      width: 340px;
      height: 340px;
      // margin: 0 20px 0 40px;
      // padding-left: 40px;
      margin: 1rem auto;
      float: right;
      overflow: hidden;
    }
  
    .slideshow-slide {
      width: 100%;
      height: 100%;
  
      img {
        width: 340px;
        height: 340px;
      }
    }
  }
  @include desktop {
    section {
      display: block;
      width: 100%;
    }
    .section-1 {
      padding-top: 3rem;
    }
    .section-2 {
      padding-top: 1rem;
    }
    .section-3 {
      padding-top: 2rem;
      .album-img {
        position: relative;
        top: 0;
        width: 340px;
        height: 300px;
        margin: 0 20px 0 40px;
        padding-left: 40px;
        float: right;
      } 
    }
    .subtitle {
      width: 340px;
      // margin: 1rem auto;
      margin: 0 0 1rem;
      padding: 0 .5rem;
    }
    p {
      padding: 0 3rem .75rem .5rem;
    }
    .album-img {
      position: relative;
      top: -60px;
      width: 380px;
      height: 280px;
      margin: 0 20px 0 40px;
      padding-left: 40px;
      float: right;
    }
    .slideshow-container {
      position: relative;
      top: -26px;
      width: 340px;
      height: 340px;
      // margin: 0 20px 0 40px;
      // padding-left: 40px;
      margin: 1rem auto;
      float: right;
      overflow: hidden;
    }
  
    .slideshow-slide {
      width: 100%;
      height: 100%;
  
      img {
        width: 340px;
        height: 340px;
      }
    }
  }
  @include ldesktop {
    section {
      display: block;
      width: 100%;
    }
    .section-1 {
      padding-top: 3rem;
    }
    .section-2 {
      padding-top: 1rem;
      .content {
        margin-bottom: 3rem;
      }
      .page-border {
        margin: 5rem 0 2rem;
      }
    }
    .section-3 {
      padding-top: 1rem;
    }

    .subtitle {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 480px;
      margin: 0 0 2rem;
    }
    h3 {
      display: inline-block;
    }
    p {
      padding: 0 3rem .75rem .5rem;
    }
    .album-img {
      position: relative;
      top: 0;
      width: 460px;
      height: 400px;
      margin: 0 20px 0 40px;
      padding-left: 40px;
      float: right;
    }
    .slideshow-container {
      position: relative;
      top: -26px;
      width: 480px;
      height: 460px;
      margin: 1rem 0 0 20px;

      float: right;
      overflow: hidden;
    }
  
    .slideshow-slide {
      width: 100%;
      height: 100%;
  
      img {
        width: 460px;
        height: 460px;
      }
    }
  }
}


// !VA Accordion
.accordion {
  @include mphone {
    margin: 0;
    .accordion__content {
      display: block;
      .accordion-video {
        display: block;
        width: 335px;
        height: 188px;
        margin: 1rem auto;
        padding: 0;
        float: right;
        iframe {
          display: block;
          width: 335px;
          height: 188px;
          margin: 0 auto;
        }
      }
      .album-img {
        margin-top: 75px;
      }
    }
  }
  @include lphone {
    margin: 0;
    .accordion__content {
      display: block;
      .accordion-video {
        display: block;
        width: 374px;
        height: 210px;
        margin: 1rem auto;
        padding: 0;
        float: right;
        iframe {
          display: block;
          width: 100%;
          height: 100%;
          margin: 0 auto;
        }
      }
      .album-img {
        margin-top: 75px;
      }
    }
  }
  @include tablet {
    margin: 0;
    .accordion__content {
      display: block;
      .accordion-video {
        display: block;
        width:  360px;
        height: 203px;
        margin: 1rem auto;
        float: right;
        iframe {
          display: block;
          width: 360px;
          height: 203px;
          margin: 0 auto;
        }
      }
      .album-img {
        margin-top: 75px;
      }
    }
  }
  @include desktop {
    margin: 0;
    .accordion__title {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: flex-start;
      padding: 0 1rem;
      h3 {
        width: 100%;
        margin: .125rem 0 .125rem 1rem;
        font-size: 1.625rem;
        text-align: left;
        &.active {
          color: var(--link-color);
        }
      }
    }
    .accordion__content {
      display: block;
      .accordion-video {
        display: block;
        width:  400px;
        height: 225px;
        margin: 1rem 0 1rem 1rem;
        float: right;
        padding: 0 1rem 0 0;
        padding: 0;
        iframe {
          display: block;
          width: 400px;
          height: 225px;
          margin: 0 auto;
        }
      }
      .album-img {
        margin-top: 20px;
      }
    }
  }
  @include ldesktop {
    margin: 0;
    .accordion__title {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: flex-start;
      padding: 0 1rem;
      h3 {
        width: 100%;
        margin: .125rem 0 .125rem 1rem;
        font-size: 1.625rem;
        text-align: left;
        &.active {
          color: var(--link-color);
        }
      }
    }
    .accordion__content {
      display: block;
      .accordion-video {
        width: 500px;
        height: 315px;
        margin-top: 10px;
        margin-right: 40px;
        margin-left: 30px;
        float: right;
        iframe {
          display: block;
          width: 500px;
          height: 315px;
          margin: 0 auto;
        }
      }
      .album-img {
        margin-top: 20px;
      }
    }
  }
}


.contact-form {
  @include mphone {
    width: 315px;
  }
  @include lphone {
    width: 354px;
  }
  @include tablet {
    width: 588px;
  }
  @include desktop {
    width: 600px;
  }
  @include ldesktop {
    width: 700px;
  }
}

.contact-form--title {
  @include mphone {
    padding-top: 3rem;
    padding-bottom: 1rem;
    h2 { font-size: 1.5rem; }
    .horiz-rule-above { padding-bottom: .5rem; }
    .horiz-rule-below { padding-top: .5rem; }
  }
  @include lphone {
    padding-top: 3rem;
    padding-bottom: 1rem;
    h2 { font-size: 1.625rem; }
    .horiz-rule-above { padding-bottom: .5rem; }
    .horiz-rule-below { padding-top: .5rem; }
  }
  @include tablet {
    padding-top: 5rem;
    padding-bottom: 4rem;
    h2 { font-size: 2.5rem; }
    .horiz-rule-above { padding-bottom: 1rem; }
    .horiz-rule-below { padding-top: 1rem; }
  }
  @include desktop {
    padding-top: 5rem;
    padding-bottom: 4rem;
    h2 { 
      font-size: 2.75rem; 
      letter-spacing: .1rem;
    }
    .horiz-rule-above { padding-bottom: 1rem; }
    .horiz-rule-below { padding-top: 1rem; }
  }
  @include ldesktop {
    padding-top: 3rem;
    padding-bottom: 2rem;
    h2 { 
      font-size: 3rem; 
      letter-spacing: .1rem;
    }
    .horiz-rule-above { padding-bottom: 1.25rem; }
    .horiz-rule-below { padding-top: 1.25rem; }
  }
}


.contact-form--feedback {
  @include mphone {
    height: 120px;
    p { font-size: 1.125rem; }
  }
  @include lphone {
    height: 140px;
    p { 
      font-size: 1.25rem; 
      line-height: 1.865rem;
    }
  }
  @include tablet {
    height: 160px;
    p { 
      font-size: 1.5rem; 
      line-height: 2.25rem;
    }
  }
  @include desktop {
    height: 160px;
    p { 
      font-size: 1.625rem; 
      line-height: 2.5rem;
    }
  }
  @include ldesktop {
    height: 120px;
    p { font-size: 1.625rem; }
    line-height: 2.5rem;
  }
}

.contact-form--input {
  @include mphone {
    height: 380px;
    padding: 1rem 0;
    input {
      margin-top: .125rem;
      padding: 10px;
      font-size: 1rem;
    }
    textarea {
      padding: 30px;
    }
    button {
      width: 140px;
    }
  }
  @include lphone {
    height: 500px;
    padding: 1rem 0;
    input {
      margin-top: .125rem;
      padding: 10px;
      font-size: 1rem;
    }
    textarea {
      padding: 80px;
    }
    button {
      width: 140px;
    }
  }
  @include tablet {
    height: 600px;
    padding: 1rem 0;
    input {
      margin-top: .125rem;
      padding: 20px;
      font-size: 1rem;
    }
    textarea {
      padding: 100px;
    }
    button {
      width: 180px;
      font-size: 1.25rem;
    }
  }
  @include desktop {
    height: 600px;
    padding: 1rem 0;
    input {
      margin-top: .125rem;
      margin-bottom: 1rem;
      padding: 10px;
      font-size: 1rem;
    }
    textarea {
      padding: 100px;
    }
    button {
      width: 180px;
      font-size: 1.25rem;
    }
  }
  @include ldesktop {
    height: 600px;
    padding: 1rem 0;
    input {
      margin-top: .125rem;
      margin-bottom: 1rem;
      padding: 10px;
      font-size: 1rem;
    }
    textarea {
      padding: 100px;
    }
    button {
      width: 180px;
      font-size: 1.25rem;
    }
  }
}
