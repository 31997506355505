.slideshow-image {
  height: 600px;
  width: 600px;
}

.slideshow-container {
  overflow: auto;
  position: relative;
}

.slideshow-slider {
  position: absolute;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

