.devred {
  border: 1px solid red !important;
}
.devblue {
  border: 1px solid blue !important;
}
.devgreen {
  border: 1px solid green !important;
}
.devmag {
  border: 1px solid magenta !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}



body {
  // position: fixed;
}

html {
  background-color: var(--primary_bg-color);
  color: var(--primary_text-color);
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  overflow-y: scroll;
}

.App {
  position: relative;
  // width: 100%;
  // box-sizing: border-box;

}

.site-wrapper {
  // !VA Center site wrap on device screen
  // display: flex;

  // flex-direction: column;
  // align-items: center;
  // width: 100%;
  // height: 100%;
  display: block;
  position: relative;
  width: 320px;
  height: 100vh;
  margin: 0 auto;
}


// !VA Main container for all non-header content. 
main {
  // position: absolute;
  position: absolute;
  top: 55px;
  width: 100%;
}

header {
  display: flex;
  position: fixed;
  top: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  border-bottom: 1px solid #212121;
  background-color: var(--header-color);
  z-index: 5;
  // display: none;
  .bg-noise {
    height: 100%;
  }
}

.header-content {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  
}

.header-content-items {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
}

// .header-content-items-mobile {
//   display: none;
//   position: relative;
//   justify-content: space-between;
//   width: 80%;
//   margin: .5rem auto 1.5rem;
// }

// !VA Get rid of this, put spacing on the page element
.header-space-below {
  display: block;
  position: relative;
  flex-shrink: 0;
  // height: 60px;
  height: 0;
}


.btn {
  display: block;
  position: relative;
  margin: 0 auto;
}

.read-more {
  display: none;
}

.read-more-button {
  // width: 180px;
  width: 120px;
  height: 40px;
  background-color: var(--button-color);
  color: var(--primary_icon-color);
  font-size: .875rem;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}


// !VA Change to .page-wrap
.page-wrap {
  position: relative;
}

.page {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 55px);
  &.page-home-1 {
    background-color: var(--secondary_bg-color);
  }
  // !VA Override vh-based page height for all pages except home.
  &.page-about, &.page-songs, &.page-bios, &.page-home-2 {
    height: auto;
    // background-color: #f9f9f9 !important;
    scroll-margin: 55px;
  }
}


.content {
  width: 100%;
}

// !VA 2022.08.24
// .page-home-2, .page-about {

//   background-color: #f9f9f9 !important;
//   p {
//     color: #1c1c1c !important;
//     font-weight: 400 !important;
//   }
//   h2, h3 {
//     color: #1c1c1c !important;
//     font-family: var(--secondary_font);
//     font-weight: 700 !important;
//   }
//   .back-to-top {
//     color: #1c1c1c !important;
//     // font-family: var(--secondary_font);
//     font-weight: 700 !important;
//     font-size: 18px;
//   }
// }






.home-crossroads {
  section {
    display: block;
  }
  .figure-desktop {
    display: none;
  }
  .figure-mobile {
    display: block;
    width: 160px !important;
    margin: 1rem  auto;
  }
  .back-to-top {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2rem 0 1rem;
    background: transparent;
    z-index: 3;
  }
  h3 {
    // padding: 0 0 1rem;
    display: block;
    margin: 0 auto;
    // color: #ECECF6;
    font-family: var(--secondary_font);
    font-weight: 700;
    // text-shadow: -3px -3px 3px #000, 3px 3px 3px #000;
    &.rule-above {
      &:before {
        top: -1px;
        border-top: 1px solid var(--horiz-rule-color);
      }
    }
    
    &.rule-below {
      &:after {
        bottom: 6px;
        border-bottom: 1px solid var(--horiz-rule-color);
      }
    }

  }
  p {
    color: var(--primary_text-color);
    font-weight: 400;
  }
}


.container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}


.home-landing {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  .grid-item-1, .grid-item-2, .grid-item-3, .grid-item-4, .grid-item-5 {
    display: flex;
    justify-content: center;
    width: 100%;
    grid-column-start: 1;
  }
  .grid-item-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 14vh;
    // !VA Align the children, i.e. the horizontal rules
    h1 {
      padding: .125rem 0 .25rem;
      color: var(--secondary_text-color);
      font-family: var(--secondary_font);
      font-size: 2.5rem;
      font-weight: 700;
      letter-spacing: 2px;
      line-height: 2.5rem;
      text-align: center;
      vertical-align: baseline;
    }
  }
  .grid-item-2 {
    height: 30vh;
    position: relative;
    .hero-img {
      width: 100%;
      height: 100%;
      background-position: center -80px;
    }
  }
  .grid-item-3 {    
    height: 16vh;
    h2 {
      color: var(--secondary_text-color);
    }
    
  }
  .grid-item-4 {
    display: none;
    color: var(--secondary_text-color);
  }
  .grid-item-5 {
    height: 27vh;
    overflow: hidden;
    .bandpic-img {
      width: 100%;
      background-repeat: no-repeat;
      // background-position: -5px center;
      background-position: -30px 0;
      background-size: 120%;
    }
  }
  color: var(--primary_text-color);
}

.rule-above, .rule-below {
  position: relative;
  text-align: center;

  &:before, &:after {
    position: absolute;
    display: block;
    margin: 0 auto;
    content: '';
    width: 100%;
  }
}

.rule-above {
  &:before {
    top: 2px;
    border-top: 1px solid var(--horiz-rule-color);
  }
}

.rule-below {
  &:after {
    bottom: 2px;
    border-bottom: 1px solid var(--horiz-rule-color);
  }
}

// !VA Horizontal Rules
.title-text {
  .rule-above {
    &:before {
      top: -2px;
    }
  }
  .rule-above {
    &:after {
      bottom: -4px;
    }
  }
}
.urlbar__url-text {
  .horiz-rule-above, .horiz-rule-below {
    display: block;
  }
}

.title {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 1.25rem 0;
}



.urlbar {
  display: block;
  position: absolute;
  top: 37vh;
  width: 100%;
  height: auto;
  background: rgb(0, 0, 0, .3);
  z-index: 4;
}



.content__url-text {
  // !VA Position the url bar without rule above or below on the hero image for phones
  // left: 0;
  width: 100%;
  font-size: .875rem;
  letter-spacing: .18rem;
  line-height: 1.5rem;
  text-align: center;
  z-index: 20;
  &.rule-above {
    &:after, &:before {
      border: 0;
    }
  }
}

// div > p  {
//   background: rgba(255, 0, 0, .3) !important; //devred
// }


.urlbar-link {
  color: var(--secondary_text-color);
}

.intro__separator {
  display: none;
  margin: 1rem 0 .5rem;
}


.hero-img {
  position: relative;
  width: 300px;
  height: 220px;
  background-repeat: no-repeat;
  background-position: center -56px;
  background-size: cover;
}

// !VA Intro Subtitle
.subtitle {
  // display: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  padding: 1rem 0 .5rem;

  .separator {
    display: flex;
    height: 20px;
    .dot-separator {
      display: flex;
      width: 300px;
    }
  }
}

// !VA Intro Description


.content-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

// .bandpic {
//   display: block;
//   position: relative;
// }


// !VA ABOUT Section

.about {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  .subtitle {
    // display: block;
    // width: 100%;
    margin: 1rem auto;
    padding: 0 1rem;
    h3 {
      margin: 0;
      padding: .25rem 0;
      color: var(--primary_text-color);
      font-family: var(--secondary_font);
      font-weight: 700;
    }
  }
  .album-img {
    width: 240px;
    height: 240px;
    margin: 1rem auto;
    overflow: hidden;
  }


  .slideshow-container {
    width: 240px;
    height: 240px;
    margin: 1rem auto;
    overflow: hidden;
  }

  .slideshow-slide {
    width: 100%;
    height: 100%;
    // margin: 1rem auto;

    img {
      width: 240px;
      height: 240px;
    }
  }
  p {
    color: var(--primary_text-color);
    font-weight: 400;
  }
}

// !VA CONTACT FORM


.page-contact {
  display: block;
  height: 100vh;
}


// .form-container {
//   display: flex;
//   position: relative;
//   flex-direction: column;
//   align-items: center;
//   justify-content: flex-start;
//   width: 300px;
//   margin: 10px auto 0;
// }

.contact-form {
  display: block;
  position: relative;
  width: 280px;
  margin: 0 auto;
  color: var(--primary_text-color);
  box-sizing: border-box;
}

.contact-form--title {
  padding-top: 2rem;
  h2 {
    width: 100%;
    margin: 0;
    font-family: var(--secondary_font);
    font-size: 1.363rem;
    letter-spacing: .03rem;
    line-height: 1.75rem;
    text-align: center;
  }
  .horiz-rule-above, .horiz-rule-below {
    margin: 0;
  }
  .horiz-rule-above {
    padding-bottom: .25rem;
  }
  .horiz-rule-below {
    margin: 0;
    padding-top: .25rem;
    padding-bottom: 0;
  }
}

.contact-form--feedback {
  height: 100px;
  padding: 1rem 0;
  p {
    font-size: 1rem;
  }
}

.contact-form--input > div {
  width: 100%;
}

.contact-form--input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 330px;
  padding: 1rem 0;
  input, textarea {
    width: 100%;
    margin-top: .125rem;
    padding: 10px;
    transition: all .3s;
    border: 1px solid #666666; 
    outline: none;
    background: none;
    color: var(--primary_text-color);
    font-family: var(--primary_font);
    font-size: 14px;
    resize: none;
    box-sizing: border-box;
    &:focus {
      border: 2px solid #78788C;
    }
  }
  .contact-form-send {
    display: block;
    width: 140px;
    margin: 8px auto 0;
    padding: 8px 12px;
    transition: all .3s;
    border: 0; 
    background: var(--header-color);
    color: var(--primary_text-color);
    font-family: var(--primary-font);
    text-align: center;
    cursor: pointer;
  }
}


// !VA Accordion
.accordion-container {
  width: 100%;
  margin: 1rem auto;
  scroll-margin: 75px;
}

.accordion {
  width: 100%;
  margin: 0 0 1rem;
  cursor: pointer;
  .separator {
    display: flex;
    height: 10px;
    .dot-separator {
      display: flex;
      width: 100%;
    }
  }
  .accordion__title {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    padding: 0 1rem;

    h3 {
      width: 100%;
      margin: .125rem 0 .125rem 1rem;
      color: var(--primary_text-color);
      font-family: var(--secondary_font);
      font-size: 1.365rem;
      font-weight: 700;
      text-align: left;
      &.active {
        color: var(--link-color);
      }
      // .active {
      //   background: black;
      // }
    }
    .accordion__icon {
      position: relative;
      width: 20px;
      height: 20px;
      margin-right: .5rem;
      svg {
        position: absolute;
        top: -4px;
        width: 100%;
        height: 100%;
        color: #8e8e8e;
      }
    }
  }
  .accordion__content {
    display: flex;
    flex-direction: column-reverse;
    p {
      width: 100%; 
      padding-top: .75rem;
    }
    .accordion-video {
      display: block;
      margin: 0 auto;
      width: 300px;
      height: 169px;
      padding: .75rem 1rem;
      iframe {
        width: 100%;
        height: auto;
      }
    }
  }
}


