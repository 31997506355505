

// !VA Typography and Accents
h2 {
  @include mphone {
    font-size: 1.75rem;
    font-weight: 700;
    letter-spacing: .04rem;
  }
  @include lphone {
    font-size: 1.813rem;
    font-weight: 700;
    letter-spacing: .04rem;
  }
  @include tablet {
    font-size: 2.25rem;
    font-weight: 700;
    letter-spacing: .04rem;
  }
  // @include desktop {
  //   font-size: 2.5rem;
  //   font-weight: 700;
  //   letter-spacing: .04rem;
  // }
  // @include ldesktop {
  //   font-size: 2.5rem;
  //   font-weight: 700;
  //   letter-spacing: .04rem;
  // }
  font-family: var(--secondary_font);
  font-size: 1.365rem;
  font-weight: 700;
  letter-spacing: .08rem;
  text-align: center;
  vertical-align: baseline;
}


h3 {
  @include mphone {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }
  @include lphone {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  @include tablet {
    padding-bottom: .375rem;
    font-size: 1.75rem;
    line-height: 2rem;
  }
  @include desktop {
    padding-bottom: .375rem;
    font-size: 2rem;
    line-height: 2.75rem;

  }
  @include ldesktop {
    @include ldesktop {
      font-size: 2.5rem;
      line-height: 3.25rem;
    }
  }
  display: inline-block;
  margin: .365rem .75rem;
  padding-bottom: .5rem;
  font-family: var(--secondary_font);
  font-size: 1.717rem;
  font-weight: 400;
  line-height: 1.75rem;
  text-align: center;
  &.rule-above:before {top: 2px; }
  &.rule-below:after { bottom: -1px; } 
}

.text-content {
  @include mphone {
    padding: 0 0 1rem;
    font-size: 1.25rem;
    line-height: 2rem;
  }
  @include lphone {
    font-size: 1.365rem;
    line-height: 2.125rem;
  }
  @include tablet {
    padding-bottom: 1rem;
    font-size: 1.25rem;
    line-height: 2.125rem;
  }
  @include desktop {
    margin: 0 0 1.25rem;
    font-size: 1.365rem;
    line-height: 2.25rem;

  }
  @include ldesktop {
    margin: 0 0 1.25rem;
    padding-bottom: .5rem;
    font-size: 1.625rem;
    line-height: 2.5rem;
  }
  padding: 0 1rem .75rem;
  font-family: var(--primary_font);
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.75rem;
}

.text-light-emphasis {
  font-style: italic;
}

.text-center {
  text-align: center;
}

.semibold {
  font-weight: 600;
}

a {
  color: var(--link-color);
  font-weight: 400;
}


