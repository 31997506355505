:root {
--primary_bg-color: #f9f9f9;
// --secondary_bg-color: #135f70;
--secondary_bg-color: #000000;
--header-color: #001619;
--header-color: #003c44;
--primary_text-color: #e2e2e2;
--secondary_text-color: #ffffff;
--primary_text-color: #1c1c1c;
--secondary_text-color: #e2e2e2;
--primary_font: 'Open Sans', sans-serif;
--secondary_font: 'Lora', serif;
--primary_accent-color: #474747;
--secondary_accent-color: #2d2d2d;
--horiz-rule-color: #666666;
--link-color: #007e8e;
--primary_icon-color: #d3d3d3;
--button-color: #841117;
}
